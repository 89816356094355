import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import germanIndex from "./locales/index/de.json";
import germanPreise from "./locales/preise/de.json";
import germanFaq from "./locales/faq/de.json";
import germanLinks from "./locales/links/de.json";
import germanHeader from "./locales/header/de.json";
import germanFooter from "./locales/footer/de.json";
import germanApiDoc from "./locales/api-doc/de.json";

import englishIndex from "./locales/index/en.json";
import englishPreise from "./locales/preise/en.json";
import englishFaq from "./locales/faq/en.json";
import englishLinks from "./locales/links/en.json";
import englishHeader from "./locales/header/en.json";
import englishFooter from "./locales/footer/en.json";
import englishApiDoc from "./locales/api-doc/en.json";

const resources = {
  de: {
    index: germanIndex,
    preise: germanPreise,
    faq: germanFaq,
    links: germanLinks,
    header: germanHeader,
    footer: germanFooter,
    apiDoc: germanApiDoc,
  },
  en: {
    index: englishIndex,
    preise: englishPreise,
    faq: englishFaq,
    links: englishLinks,
    header: englishHeader,
    footer: englishFooter,
    apiDoc: englishApiDoc,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "de",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["em", "strong", "br", "p"],
    },
  });

export default i18n;
