import "@popperjs/core/dist/umd/popper.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "./src/stylesheets/main.scss";

import React from "react";
import "./src/i18n";

export const wrapRootElement = ({ element }) => {
  return <>{element}</>;
};
